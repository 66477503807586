import { useScreenHeight } from "../utils/windowSize"
import { useGlobal } from "reactn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const Card = (props) => {
  return (
    <Link to={props.link}
      className="flex w-full flex-col lg:flex-row text-left shadow shadow-[4px_4px_10px_0px_rgba(0,0,0,0.3)] mb-7
      hover:shadow-[2px_2px_5px_0px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-300
      active:transition-none"
    >
      <div
        className="relative overflow-hidden w-full h-[200px] sm:h-[274px]"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: "center",
          backgroundSize: "140% auto"
        }}
      >
        <div className="inline-block bg-gold px-8 py-2 text-sm jet-brains-mono"><i>Our Programs</i></div>
      </div>
      <div className="flex flex-col justify-between lg:h-[274px] w-full px-8 py-8">
        <p className="text-black text-base lg:text-sm xl:text-base mb-8 jet-brains-mono">{props.description}</p>
        <div className="flex flex-row items-center justify-between">
          <div className="text-base lg:text-sm xl:text-base font-extralight jet-brains-mono">
            Learn more about <br /> what we do here!
          </div>


          <FontAwesomeIcon icon={faArrowRight}
            className="text-black border border-black hover:text-white hover:bg-black transition-all ease-in-out duration-100"
            style={{
              width: "40px",
              height: "40px",
              padding: "5px",
              borderRadius: "50%",
              borderWidth: "2px"
            }}
          />
        </div>
      </div>
    </Link>
  )
}

const SmallCard = (props) => {
  return (
    <Link to={props.link}
      className="text-left flex flex-col bg-gold w-full px-6 py-8 shadow shadow-[4px_4px_10px_0px_rgba(0,0,0,0.3)] mb-7
        hover:shadow-[2px_2px_5px_0px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-300 active:transition-none">
      <h1 className="text-black text-xl lg:text-2xl xl:text-4xl monomaniac-one mb-4 opacity-85">{props.title}</h1>
      <div className="flex flex-row items-center space-between gap-4 lg:gap-8">
        <div className="text-xs lg:text-sm font-extralight jet-brains-mono">
          {props.description}
        </div>


        {/* <FontAwesomeIcon icon={faArrowRight}
          className="text-black border border-black hover:text-white hover:bg-black transition-all ease-in-out duration-100"
          style={{
            width: "40px",
            height: "40px",
            padding: "5px",
            borderRadius: "50%",
            borderWidth: "2px"
          }}
        /> */}
      </div>
    </Link>

  )
}

const ContactUsCard = (props) => {
  return (
    <Link to={props.link}
      className="text-left flex flex-col bg-gold w-full tablet:w-1/2 px-6 py-6 rounded-tr-[36px] shadow shadow-[4px_4px_10px_0px_rgba(0,0,0,0.3)]
      hover:shadow-[2px_2px_5px_0px_rgba(0,0,0,0.3)] active:shadow-[0px_0px_1px_0px_rgba(0,0,0,0.3)]
      transition-all ease-in-out duration-300 active:transition-none"
    >
      <div className="flex flex-row justify-between w-full">
        <h1 className="text-black text-xl lg:text-2xl xl:text-4xl monomaniac-one mb-4">{props.title}</h1>

        <FontAwesomeIcon icon={faChevronRight}
          className="text-black"
          style={{
            width: "20px",
            height: "20px",
            padding: "5px",
          }}
        />
      </div>

      <div className="text-xs lg:text-sm font-extralight jet-brains-mono">
        {props.description}
      </div>
    </Link>

  )
}

const DownArrow = (props) => {
  return (
    <div className="relative">
      <div
        className="flex content-center justify-center flex-end items-center"
        style={{
          width: `${props.tailLength}px`,
        }}
      >
        <div
          className="bg-gold rounded"
          style={{
            width: `${props.thickness}px`,
            height: `${props.height}px`,
          }}
        ></div>
      </div>
      <div
        className={`border border-gold`}
        style={{
          position: "absolute",
          bottom: "0px",
          width: `${props.tailLength}px`,
          height: `${props.tailLength}px`,
          borderWidth: `0px ${props.thickness}px ${props.thickness}px 0px`,
          borderRadius: `${props.thickness}px`,
          transform: "rotate(45deg)",
        }}>
      </div>
    </div>
  )
}

const Overview = (props) => {
  const [global] = useGlobal()
  const screenHeight = useScreenHeight()
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div
        className="relative overflow-hidden bg-dark-green px-10 mobile:px-20 md:px-40 py-20"
        style={{ height: screenHeight - global.navbarHeight }}>
        <div
          className={`block absolute bg-gold opacity-40`}
          style={{
            width: `${screenHeight - global.navbarHeight}px`,
            height: `${screenHeight - global.navbarHeight}px`,
            borderRadius: '50%',
            bottom: '0px',
            right: `-${(screenHeight - global.navbarHeight) / 2}px`,
          }}
        ></div>
        <div
          className={`block absolute bg-gold opacity-60`}
          style={{
            width: `${(screenHeight - global.navbarHeight) * 0.75}px`,
            height: `${(screenHeight - global.navbarHeight) * 0.75}px`,
            borderRadius: '50%',
            bottom: '0px',
            right: `-${((screenHeight - global.navbarHeight) * 0.75) / 2}px`,
          }}
        ></div>
        <div
          className={`block absolute bg-gold opacity-80`}
          style={{
            width: `${(screenHeight - global.navbarHeight) * 0.5}px`,
            height: `${(screenHeight - global.navbarHeight) * 0.5}px`,
            borderRadius: '50%',
            bottom: '0px',
            right: `-${((screenHeight - global.navbarHeight) * 0.5) / 2}px`,
          }}
        ></div>
        <div
          className={`block absolute bg-gold`}
          style={{
            width: `${(screenHeight - global.navbarHeight) * 0.25}px`,
            height: `${(screenHeight - global.navbarHeight) * 0.25}px`,
            borderRadius: '50%',
            bottom: '0px',
            right: `-${((screenHeight - global.navbarHeight) * 0.25) / 2}px`,
          }}
        ></div>
        <div className="flex flex-col justify-center text-left w-full h-full tablet:w-1/2">
          <h1 className="text-white text-4xl lg:text-5xl monomaniac-one mb-5">
            Welcome to <br />
            <span className="text-gold">Al-Haqq Foundation</span>
          </h1>
          <p className="text-white text-xl lg:text-2xl jet-brains-mono font-medium mb-16">
            We seek to strengthen our community by offering specialized programs that deliver Islamic education and essential social services
          </p>
          <DownArrow
            height={50}
            thickness={2}
            tailLength={10}
            color={'gold'}
          />
        </div>
      </div>

      {/* -------------------- Block 2 -------------------- */}
      <div className="bg-white px-10 mobile:px-20 md:px-40 py-20">
        <div className="text-left mb-10">
          <h1 className="text-gold text-5xl monomaniac-one">
            Teaching. Providing. Caring.
          </h1>
          {/* <p className="text-black text-lg lg:text-lg jet-brains-mono font-medium">
            We seek to strengthen our community by offering specialized programs that deliver Islamic education and essential social services
          </p> */}
        </div>
        <Card
          image={'https://www.londontourism.ca/mediafiles/social/26-meetings-top10-reasons-header-image.jpg'}
          link={"/about-us/programs"}
          description={"Our work is tailored toward the needs of the community we represent. To achieve this, we strive to provide education and social services with an Islamic focus to target problems with a holistic methodology."}
        />

        <div className="flex flex-col sm:flex-row sm:gap-8">
          <SmallCard title={"Explore more about who we are"} description={"Al-Haqq Foundation is an organization with a dynamic mission. Learn more about it here!"} link={"/about-us/overview"} />
          <SmallCard title={"Learn how to get involved"} description={"Volunteerism is critical in what we do. Get involved with us to help us provide for the community!"} link={"/get-involved/volunteering"} />
        </div>
      </div>

      {/* -------------------- Block 3 -------------------- */}
      <div className="bg-dark-green px-10 mobile:px-20 md:px-40 py-20 text-left">
        <div className="flex flex-col gap-8">
          <h1 className="text-gold text-4xl monomaniac-one">Support our Efforts!</h1>

          <div className="flex flex-col border border-l-2 border-0 border-gold ml-[2px] pl-4 gap-8">
            <div className="text-white jet-brains-mono">
              To give charity publicly is good, but to give to the poor privately is better for you,
              and will absolve you of your sins. And Allah is All-Aware of what you do.
            </div>
            <div className="text-gold text-right text-lg jet-brains-mono">
              <b><div class="text-gold text-right text-lg jet-brains-mono">اِنۡ تُبۡدُوا الصَّدَقٰتِ فَنِعِمَّا هِىَ​ۚ وَاِنۡ تُخۡفُوۡهَا وَ تُؤۡتُوۡهَا الۡفُقَرَآءَ فَهُوَ خَيۡرٌ لَّكُمۡ​ؕ وَيُكَفِّرُ عَنۡكُمۡ مِّنۡ سَيِّاٰتِكُمۡ​ؕ وَاللّٰهُ بِمَا تَعۡمَلُوۡنَ خَبِيۡرٌ‏ ٢٧١</div></b>
            </div>
            <div className="text-gold jet-brains-mono">
              Quran [2:270-271]
            </div>
          </div>

          <div className="flex flex-col gap-4 text-white text-sm jet-brains-mono">
            <div>
              Consider donating to our cause to help us sustain our programs!
            </div>
            <div>
              Aid us us a one-time donation or become one of our monthly donors through the link below!
            </div>
          </div>


          <h1 className="text-gold text-5xl monomaniac-one underline">
            <Link to={"/donate"}>Donate Here!</Link>
          </h1>


          <ContactUsCard title={'Contact Us!'} description={'If you have any questions, concerns, or feedback, reach out to us and we will do our best to provide you with the information you need!'} link={'/contact-us'} />

        </div>
      </div>
    </div>
  )
}

export default Overview